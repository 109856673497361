function QuoteLine() {

    const bodyFontSize = parseFloat(getComputedStyle(document.body).fontSize);
    const lineHeight = 1.5;

    const quoteLineContainerStyle = {
        display: 'flex',
        alignItems: 'center', // Vertically align the line and the text
        position: 'relative',
        marginTop: `-${bodyFontSize * lineHeight * lineHeight}px`, // Adjust this value as needed
    };

    const quoteLineLineStyle = {
        position: 'absolute',
        top: `-${bodyFontSize}px`,
        width: '7px', // Adjust the width of the quote line
        height: `${bodyFontSize * lineHeight * 2}px`, // Set the height based on text size
        backgroundColor: 'grey', // Adjust the color of the quote line
    };


    return (
        <div style={quoteLineContainerStyle}>
            <div style={quoteLineLineStyle}></div>
        </div>
    );
}

export default QuoteLine;