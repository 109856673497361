import { ethers } from 'ethers';

let provider = new ethers.providers.Web3Provider(window.ethereum);

export const recreateProvider = () => {
    provider = new ethers.providers.Web3Provider(window.ethereum);
    return provider;
};

export const getProvider = () => {
    return provider;
}