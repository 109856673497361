import { ethers } from 'ethers';
import { contractConfig } from "../config/contractConfig";
import { getProvider } from "../services/provider";

const abi = [
    "event ElectionCreated(address indexed owner, uint256 indexed electionId, uint256 indexed createdAt, uint256 endTime)",
    "event Voted(address indexed voter, uint256 indexed electionId, uint256 indexed chosenCandidate, uint256 votedAt)",
    "event VoterRegistered(address indexed voter, uint256 joinedAt)",
    "function createElection(string uri, uint256 endTime, uint256 candidates)",
    "function getElection(uint256 electionId) view returns (string, address, uint256[], uint256)",
    "function hasVoted(uint256 electionId) view returns (bool)",
    "function nextElectionId() view returns (uint256)",
    "function register()",
    "function registered(address) view returns (bool)",
    "function vote(uint256 electionId, uint256 candidate)"
];

export const connect = async () => {
    await getProvider().send("eth_requestAccounts", []);
    return getContract();
}

export const getContract = async (optionalNewProvider = null) => {
    const currentProvider = optionalNewProvider || getProvider();

    let chainId;
    try {
        const chainHexId = await currentProvider.send( 'eth_chainId', []);
        chainId = parseInt(chainHexId, 16);
    } catch (err) {
        console.log("Error getting chainId: " + err.message);
    }

    const contractAddress = contractConfig[chainId];

    if (!contractAddress) {
        console.log("Connection attempt to unsupported network");
        alert("Please switch to fuji, mumbai or sepolia testnet");
        return { signer: null, contract: null };
    }
    const signer = currentProvider.getSigner();
    const contract = new ethers.Contract(contractAddress, abi, signer);
    return { signer: signer, contract: contract };
}