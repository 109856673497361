import { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import { Alert, Button, Col, Row, Spinner } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { FaChevronRight } from "react-icons/fa";
import LimitationsColumn from "../base/LimitationsColumn";
import ContentsSection from "../base/ContentsSection";
import QuoteLine from "../base/QuoteLine";

const CreateElection = ({contract, setCurrentPage}) => {
    const [uri, setUri] = useState("ipfs/QmaeiUQ3eVUfg9dGoN9Tw9AigDfw4AbQN5sBVQM88oWFxP");
    const [candidates, setCandidates] = useState(8);
    const [endDate, setEndDate] = useState("");
    const [isValidDate, setIsValidDate] = useState(true);
    const [isUriValid, setIsUriValid] = useState(true);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        return listenElectionCreated();
    }, []);

    function listenElectionCreated() {
        const eventListener = async (event) => {
            try {
                if (event) {
                    setLoading(false);
                    alert("Successfully created election");
                    setCurrentPage('elections');
                }
            } catch (error) {
                console.error("Error in event listener:", error);
            }
        };

        contract.on('ElectionCreated', eventListener);

        return () => {
            contract.off('ElectionCreated', eventListener);
        };
    }


    const handleEndDateChange = (e) => {
        const selectedDate = new Date(e.target.value);
        const currentDate = new Date();

        if (selectedDate < currentDate) {
            setIsValidDate(false);
        } else {
            setIsValidDate(true);
        }

        setEndDate(e.target.value);
    };

    const handleUriChange = (e) => {
        const inputValue = e.target.value;
        const ipfsHashPattern = /^ipfs\/[a-zA-Z0-9]{46}$/;

        if (ipfsHashPattern.test(inputValue)) {
            setIsUriValid(true);
        } else {
            setIsUriValid(false);
        }
        setUri(inputValue);
    };

    const createElection = async () => {
        if (!contract) {
            alert("Please connect your wallet first");
            return;
        }

        setLoading(true);

        try {
            const response = await fetch(`https://gateway.pinata.cloud/${uri}`);
            if (!response.ok) {
                setIsUriValid(false);
                setLoading(false);
                return
            }
        } catch (error) {
                console.error("Error in fetching from pinata, check IPFS Hash:", error);
                setLoading(false);
                setIsUriValid(false);
                return
        }

        try {
            await contract.createElection(uri, new Date(endDate).getTime(), candidates);
        } catch (err) {
            alert("Error: " + err.message);
            setLoading(false);
        }
    }

    return (
        <Container className="p-4">
            <Row>
                <Col md={8} >
                    <h1 className="py-2">
                        <strong>
                            ELECTION setup: decentralised election data in IPFS and pinned with Pinata
                        </strong>
                    </h1>

                    <div className="my-5">
                        <p style={{ paddingLeft: '20px' }}><strong>Demonstrating</strong> decentralised data; typically admin' task</p>
                        <QuoteLine />
                    </div>

                    <ContentsSection
                        contentOne={"Check decentralised election data format"}
                        contentTwo={"Pin election data to IPFS with Pinata (optional)"}
                        contentThree={"Create election"}
                    />
                </Col>
                <Col md={4}></Col>
            </Row>
            <Row>

                <Col md={8} className="my-4 pr-3">
                    <h2 className="py-1 mb-4">
                        <strong>Check decentralised election data format</strong>
                    </h2>
                    <p>Data is stored using <a target="_blank" rel="noreferrer" href="https://ipfs.tech/">IPFS</a>.
                        It is a simple JSON format as can be seen in this <a target="_blank" rel="noreferrer" href="https://gateway.pinata.cloud/ipfs/QmaeiUQ3eVUfg9dGoN9Tw9AigDfw4AbQN5sBVQM88oWFxP">example</a>.
                    </p>

                    <h2 className="py-1 mt-5 mb-4">
                        <strong>Pin election data to IPFS with Pinata (optional)</strong>
                    </h2>
                    <p>You may skip this step and use IPFS url and number of candidates, pre-filled below to create an election. To create your own:</p>
                    <ul className="p-0">
                        <li className="py-2" style={{ listStyle: 'none' }}>
                            - Sign up for a free account at <a target="_blank" rel="noreferrer" href="https://app.pinata.cloud/register">Pinata</a>
                        </li>
                        <li className="py-2" style={{ listStyle: 'none' }}>- Create a <strong>valid</strong> JSON file with the election data
                        </li>
                        <li className="py-2" style={{ listStyle: 'none' }}>- Enter the IPFS hash and number of candidates below
                        </li>
                        <li className="py-2" style={{ listStyle: 'none' }}>- Caution, there is currently <strong>no</strong> protection for mismatched number of candidates or incorrectly formed JSON file
                        </li>
                    </ul>

                    <h2 className="py-1 mt-5 mb-4">
                        <strong>Create election</strong>
                    </h2>

                        <form className="m-2">

                            <Form.Group className="m-2">
                                <label htmlFor="uri">IPFS Hash</label>
                                <Form.Control
                                    type="text"
                                    name="uri"
                                    value={uri}
                                    onChange={(e) => handleUriChange(e)}
                                />
                                {!isUriValid && (
                                    <Alert variant="danger" className="mt-2">
                                        The IPFS hash must start with 'ipfs/' and be followed by a valid 46-character hash.
                                    </Alert>
                                )}
                            </Form.Group>

                            <Form.Group className="m-2">
                                <label htmlFor="candidates">Number of Candidates</label>
                                <Form.Select
                                    name="candidates"
                                    defaultValue={8}
                                    onChange={(e) => setCandidates(e.target.value)}
                                >
                                    {[...Array(8)].map((_, index) => (
                                        <option key={index + 1} value={index + 1}>
                                            {index + 1}
                                        </option>
                                    ))}
                                </Form.Select>
                            </Form.Group>

                            <Form.Group className="m-2">
                                <label htmlFor="endDate">End Date</label>
                                <Form.Control
                                    type="date"
                                    name="endDate"
                                    value={endDate}
                                    onChange={(e) => handleEndDateChange(e)}
                                />
                                {!isValidDate && (
                                    <Alert variant="danger" className="mt-2">
                                        Please select a future date for the end date.
                                    </Alert>
                                )}
                            </Form.Group>

                            {/*TODO: validation of correctly formed JSON, matching number of candidates and disable button.*/}
                            <Form.Group className="m-2 mt-4">
                                <Button
                                    variant="success"
                                    onClick={createElection}
                                    disabled={!isUriValid || loading}
                                >
                                    {loading ? (
                                        <>
                                            <Spinner animation="border" size="sm" />
                                            &nbsp;Creating Election...
                                        </>
                                    ) : (
                                        <>
                                            <strong>Create</strong>
                                            <FaChevronRight style={{ marginLeft: '10px' }} />
                                        </>
                                    )}
                                </Button>
                            </Form.Group>

                        </form>
                </Col>

                <LimitationsColumn />

            </Row>
        </Container>

    );
};

export default CreateElection;