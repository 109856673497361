import { Col } from "react-bootstrap";

const LimitationsColumn = () => {
    return (
        <Col md={4} className="border-top p-4 pl-3">
            <h4><strong>Limitations</strong></h4>
            <p className="py-2 mb-0">
                This site is decentralised using IPFS along with the data, on which, the election relies.
            </p>
            <p className="py-2 mb-0">
                Voting is transparent and immutable being conducted on test blockchain networks.
            </p>
            <p className="py-2 mb-0">
                Being a demonstration there are some limitations:
            </p>
            <ul className="p-2">
                <li className="py-2 px-0" style={{ listStyle: 'none' }}>
                    - Alerts and errors use browser alerts for simplicity
                </li>
                <li className="py-2 px-0" style={{ listStyle: 'none' }}>
                    - Sign up and voting is restricted by wallet and not by individual
                </li>
                <li className="py-2 px-0" style={{ listStyle: 'none' }}>
                    - Elections are stored permanently on chain, however, only those in the last 2000 blocks are displayed to keep the UI uncluttered and functional per user interaction
                </li>
            </ul>
        </Col>
    )
}

export default LimitationsColumn;