import Container from "react-bootstrap/Container";
import { Button, Col, Row } from "react-bootstrap";
import QuoteLine from "../base/QuoteLine";
import { FaChevronRight } from "react-icons/fa";
import LimitationsColumn from "../base/LimitationsColumn";
import ContentsSection from "../base/ContentsSection";

const Home = ({ connect, connected, register, isRegistered, setCurrentPage }) => {
    return (
        <Container className="p-4">
            <Row>
                <Col md={8} >

                    <h1 className="py-2">
                        <strong>
                            ELECTION online: decentralised voting with blockchain wallet
                        </strong>
                    </h1>
                    <ContentsSection
                        contentOne={"Sign in to service"}
                        contentTwo={"Create a decentralised election"}
                        contentThree={"Cast your vote"}
                    />

                </Col>
                <Col md={4}></Col>
            </Row>
            <Row>
                <Col md={8} className="my-4 pr-3">

                    <h2 className="py-1 mb-4">
                        <strong>Sign in to service with a wallet</strong>
                    </h2>
                    <p>You need a blockchain wallet such as <a target="_blank" href="https://metamask.io/">Metamask</a> to sign in to vote.
                        Connect your wallet and ensure you are connected to one of the supported test networks.
                    </p>

                    <div className="my-5">
                        <p style={{ paddingLeft: '20px' }}>This demo supports three <strong>test</strong> networks</p>
                        <QuoteLine />
                    </div>

                    <section>
                        <p className="py-2 mb-0">You <strong>must</strong> connect to one of the following networks</p>
                        <ul className="p-0">
                            <li className="py-2" style={{ listStyle: 'none' }}>-
                                <a target="_blank" rel="noreferrer" href="https://chainlist.org/chain/11155111"> Sepolia Ethereum</a>
                            </li>
                            <li className="py-2" style={{ listStyle: 'none' }}>-
                                <a target="_blank" rel="noreferrer" href="https://chainlist.org/chain/43113"> Avalanche Fuji</a>
                            </li>
                            <li className="py-2" style={{ listStyle: 'none' }}>-
                                <a target="_blank" rel="noreferrer" href="https://chainlist.org/chain/80001"> Mumbai Polygon</a>
                            </li>
                        </ul>
                    </section>
                    {!connected ? (
                        <Button onClick={connect}>Connect to Metamask</Button>
                    ) : !isRegistered ? (
                        <Button onClick={register} className="mt-4" variant="success">
                            <strong>Sign Up</strong>
                            <FaChevronRight style={{ marginLeft: '10px' }} />
                        </Button>
                    ) : (
                        <Button onClick={() => setCurrentPage('create-election')} className="mt-4" variant="success">
                            <strong>Create Election</strong>
                            <FaChevronRight style={{ marginLeft: '10px' }} />
                        </Button>
                    )}

                </Col>
                <LimitationsColumn />
            </Row>
        </Container>
    );
}

export default Home;