const ContentsSection = ({ contentOne, contentTwo, contentThree }) => {
    return (
        <section className="border-bottom">
            <p className="py-1 mb-0">Contents</p>
            <ul className="p-0">
                <li className="py-1" style={{ listStyle: 'none' }}>
                    <a>- {contentOne}</a>
                </li>
                <li className="py-1" style={{ listStyle: 'none' }}>
                    <a>- {contentTwo}</a>
                </li>
                <li className="py-1" style={{ listStyle: 'none' }}>
                    <a>- {contentThree}</a>
                </li>
            </ul>
        </section>
  );
}

export default ContentsSection;