import { useState, useEffect } from "react";
import { Button, Col, Row } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import ProgressBar from "react-bootstrap/ProgressBar";
import Container from "react-bootstrap/Container";
import LimitationsColumn from "../base/LimitationsColumn";
import ContentsSection from "../base/ContentsSection";


const Elections = ({ contract, setCurrentPage }) => {
    const gateway = "https://gateway.pinata.cloud/";
    const [elections, setElections] = useState([]);

    useEffect(() => {
        if (!contract) return;

        const filter = contract.filters.ElectionCreated();

        contract.provider.getBlockNumber()
            .then(latestBlockNumber => {
                const fromBlock = Math.max(0, latestBlockNumber - 2000 + 1);

                return contract.queryFilter(filter, fromBlock);
            })
            .then((elections) => {
                setElectionsData(elections);
            })
            .catch((err) => console.log(err));
    }, [contract]);

    const vote = async (electionId, candidateId) => {
        await contract.vote(electionId, candidateId).then(() => {
            alert("Successfully voted");
        }).catch((err) => {
            alert("Error: " + err.message);
        });
    }

    const setElectionsData = async (elections) => {
        const promises = [];
        const newElections = [];

        for (const election of elections) {
            const { owner, electionId, createdAt, endTime } = election.args;
            const promise = contract.getElection(electionId).then( async (electionData) => {
                const uri = electionData[0];
                if (!uri) return;
                const currentVotes = electionData[2];
                // returned as string from chain so map to numbers
                const currentVotesNumbers = currentVotes.map((vote) => vote.toNumber());

                const newElection = {
                    id: electionId.toNumber(),
                    owner: owner,
                    createdAt: createdAt.toNumber(),
                    endTime: endTime.toNumber(),
                    totalVotes: currentVotesNumbers.reduce((sum, value) => sum + value, 0),
                    votes: currentVotesNumbers,
                };

                try {
                    await fetch(gateway + uri)
                        .then((res) => res.json())
                        .then((data) => {
                            newElection.description = data.description;
                            newElection.candidates = data.candidates;
                            newElections.push(newElection);
                        });
                } catch {
                    console.log("Error fetching data from IPFS");
                }
            });
            promises.push(promise);
        }
        await Promise.all(promises);
        setElections(newElections);
    }

    // Math max used to prevent divide by 0 error and NaN
    // i.e max of either 1 or the total votes
    return (
        <Container className="p-4">
            <Row>
                <Col md={8} >
                    <h1 className="py-2">
                        <strong>
                            ELECTION online: decentralised voting with blockchain wallet
                        </strong>
                    </h1>
                    <ContentsSection
                        contentOne={"Review elections"}
                        contentTwo={"Place your vote"}
                        contentThree={"Vote in further elections or switch wallet account to vote again"}
                    />

                </Col>
                <Col md={4}></Col>
            </Row>
            <Row>
                <Col md={8} className="my-4 pr-3">
                    <h2 className="py-1 mb-4">
                        <strong>Cast votes and test contract</strong>
                    </h2>

                    <p>
                        Votes can be cast for one candidate for each election.
                        Elections are unique to each network and votes unique to a wallet address.
                        Testing can be performed by switching between wallets and networks.
                    </p>

                    { elections.length === 0 ? (
                        <div className="p-5 m-5">
                            <h2>Waiting for elections...</h2>
                            <Button className="mt-5" onClick={() => setCurrentPage('create-election')} variant="primary">
                                Create New Election
                            </Button>
                        </div>
                    ) : (
                        elections.map((election) => (
                            <Card key={election.id} className="my-2 mt-4">
                                <Card.Header className="py-4"><strong>{ election.description }</strong></Card.Header>
                                <Card.Body>
                                    { election.candidates.map((candidate, candidateIndex) => (
                                        <div className="mt-1" key={Math.random() + candidateIndex}>
                                            <p>{ candidate }: {" "} {((election.votes[candidateIndex] / Math.max(1, election.totalVotes)) * 100).toFixed(0)}%</p>
                                            <div className="d-flex w-100 align-items-center">
                                                <ProgressBar
                                                    now={(election.votes[candidateIndex] / Math.max(1, election.totalVotes)) * 100}
                                                    label={`${election.votes[candidateIndex]} votes`}
                                                    className="w-100 me-2"
                                                />
                                                <Button size="sm" onClick={() => {
                                                    vote(election.id, candidateIndex);
                                                }} variant="dark">
                                                    Vote
                                                </Button>
                                            </div>
                                        </div>
                                    ))}
                                </Card.Body>
                            </Card>
                        ))
                    )}

                </Col>
                <LimitationsColumn />
            </Row>
        </Container>
    );
};

export default Elections;