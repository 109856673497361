import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/bootstrap-overrides.css';

import { useState, useEffect } from "react";

import NavbarTop from "./components/layout/NavbarTop";
import Home from "./components/pages/Home";
import CreateElection from "./components/pages/CreateElection";
import Elections from "./components/pages/Elections";

import { connect, getContract } from "./utils/contract";
import { recreateProvider } from "./services/provider";

function App() {
    const [contract, setContract] = useState(null);
    const [connected, setConnected] = useState(false);
    const [connecting, setConnecting] = useState(false);
    const [isRegistered, setIsRegistered] = useState(false);
    const [currentPage, setCurrentPage] = useState(null);

    useEffect(monitorWalletConnection, []);
    useEffect(setupWalletEventListeners, []);

    function monitorWalletConnection() {
        window.ethereum.request({ method: 'eth_accounts'}).then((accounts) => {
            if (accounts.length > 0) {
                retrieveContractFromConnection();
            } else {
                setConnected(false);
            }
        });
    }

    function setupWalletEventListeners() {
        if (window.ethereum) {
            const handleChainChange = (accounts) => {
                setIsRegistered(false);
                setContract(null);
                setCurrentPage(null);

                if (accounts.length > 0) {
                    const updatedProvider = recreateProvider();
                    retrieveContractFromConnection(updatedProvider);
                } else {
                    setConnected(false);
                    alert("Network changed, please connect again");
                }
            };

            const handleAccountsChange = (accounts) => {
                setIsRegistered(false);
                setCurrentPage(null);

                if (accounts.length > 0) {
                    const updatedProvider = recreateProvider();
                    retrieveContractFromConnection(updatedProvider);
                } else {
                    setConnected(false);
                    alert("Account changed, please connect again");
                }
            };

            window.ethereum.on('chainChanged', handleChainChange);
            window.ethereum.on('accountsChanged', handleAccountsChange);

            return () => {
                window.ethereum.removeListener('chainChanged', handleChainChange);
                window.ethereum.removeListener('accountsChanged', handleAccountsChange);
            };
        }
    }

    const retrieveContractFromConnection = (optionalNewProvider = null) => {
        setConnected(true);

        getContract(optionalNewProvider).then(({ contract, signer }) => {
            if (!contract) {
                console.log("Contract could not be retrieved, check network");
                return;
            }
            setContract(contract);
            if (contract) {
                signer.getAddress().then((address) => {
                    contract.registered(address).then((result) => {
                        setIsRegistered(result)
                    });
                });
            }
        });
    }

    const connectCallback = async () => {
        if (connecting) {
            alert("Please go to metamask and confirm the connection");
            return;
        }
        setConnecting(true);

        try {
            const { contract } = await connect();
            setContract(contract);
            if (contract) {
                setConnected(true);
                if (isRegistered) {
                    setCurrentPage('create-election');
                }
            }
        } catch (error) {
            console.error("Unexpected connection error:", error);
        } finally {
            setConnecting(false);
        }
    }

    const register = async () => {
        if (!contract) {
            alert("Please connect your wallet first");
            return;
        }
        await contract.register().then(() => {
            alert("Successfully registered");
            setIsRegistered(true);
            setCurrentPage('create-election');
        }).catch((err) => {
            alert("Error: " + err.message);
        });
    }

    const renderPage = () => {
        switch (currentPage) {
            case 'create-election':
                return <CreateElection
                    contract={contract}
                    setCurrentPage={setCurrentPage}
                />;
            case 'elections':
                return <Elections
                    contract={contract}
                    setCurrentPage={setCurrentPage}
                />;
            default:
                return <Home
                    connect={connectCallback}
                    connected={connected}
                    register={register}
                    isRegistered={isRegistered}
                    setCurrentPage={setCurrentPage}
                />;
        }
    };

    return (
        <>
            <NavbarTop
                connect={connectCallback}
                connected={connected}
                register={register}
                isRegistered={isRegistered}
                setCurrentPage={setCurrentPage}
            />
            <div className="container">
                {renderPage()}
            </div>
        </>
    );
}

export default App;