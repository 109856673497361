import { Button } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import "./NavBarTop.css";

import { GiQueenCrown } from "react-icons/gi";

// short-circuit evaluation of register to vote button
const NavbarTop = ({ connect, connected, register, isRegistered, setCurrentPage }) => {

    return (
        <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
            <Container fluid>
                <Navbar.Brand href="/">
                    <GiQueenCrown size={22} style={{ marginTop: '-5px' }}/>
                    <strong style={{ marginLeft: 10 }}>ELECTION.SOL</strong>
                </Navbar.Brand>
                <Navbar.Toggle />
                <Navbar.Collapse>
                    <Nav className="me-auto">
                        {isRegistered && (
                            <>
                                <Nav.Link onClick={() => setCurrentPage('create-election')}>Create Election</Nav.Link>
                                <Nav.Link onClick={() => setCurrentPage('elections')}>Elections</Nav.Link>
                            </>
                        )}

                        {(connected && !isRegistered) && (
                            <Button variant="success" onClick={register}>Sign Up</Button>
                        )}
                    </Nav>
                    <Nav className="ml-auto">
                        {!connected ? (
                            <Button onClick={connect}>Connect to Metamask</Button>
                        ) : (
                            <p
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    color: 'white',
                                    margin: 0,
                                }}>Connected to Wallet
                            </p>
                        )}

                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
};

export default NavbarTop;